<template>
    <div class="box">
        <Header type="expert" :navList="navList" :navCurrentId="navCurrentId" :logo="logoUrl"></Header>

        <div class="container">
            <!-- 面包屑 -->
            <div class="flex pb5 x-left flex-wrap">
                <div class="color999">您所在的位置：</div>
                <a-breadcrumb class="t-l">
                    <a-breadcrumb-item><router-link class="color999" :to="{path:'/'}">首页</router-link></a-breadcrumb-item>
                    <a-breadcrumb-item><router-link class="color999" :to="{path:'/expert/index'}">医咖秀</router-link></a-breadcrumb-item>
                    <a-breadcrumb-item><router-link class="color999" :to="{path:'/expert/committee'}">学科主委</router-link></a-breadcrumb-item>
                    <a-breadcrumb-item><router-link class="color999" :to="{path:'/expert/subject-search',query : this.queryParams}">{{queryParams.name}}</router-link></a-breadcrumb-item>
                    <a-breadcrumb-item>{{pageInfo.title}}</a-breadcrumb-item>
                </a-breadcrumb>
            </div>

            <div class="content mt20">
                <h1>{{pageInfo.title}}</h1>
                <div class="count" v-if="pageInfo.count">{{pageInfo.count}}名</div>
                <div class="block mt20">
                    <a-spin :spinning="loading" tip="Loading...">
                        <div class="item-box mt20">
                            <expert-list type="2" :dataList="pageInfo.list" v-show="!loading"></expert-list>
                            <a-empty style="margin-top:100px;" v-if="!pageInfo.list.length && !loading" />
                        </div>
                    </a-spin>
                    <div class="t-c mt20">
                        <a-pagination
                            v-model="paginationParams.page"
                            show-quick-jumper
                            show-size-changer
                            :pageSizeOptions="pageSizeOptions"
                            :pageSize="paginationParams.pageSize"
                            :total="pageInfo.count"
                            @change="getList"
                            @showSizeChange="handleChangeSize"
                            :hideOnSinglePage="true"
                        />
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
    import Header from "@/components/layout/layout-header";
    import Footer from "@/components/layout/layout-footer";
    import expertList from '@/components/expert-list';
    import {expertNavList, exportLogo} from "@/config/const";
    import {jsonFormat} from "@/utils/jsonFormat";

    export default {
        name: "subject-details",
        components : {
            Header , Footer , expertList
        },
        data(){
            return{
                logoUrl : exportLogo,
                navList : expertNavList,//顶部菜单
                navCurrentId : 4,//当前页面菜单对应下标
                pageInfo : {
                    list : []
                },
                pageSizeOptions : ['12' , '16' , '20' , '24'],
                paginationParams : {
                    page : 1, //页数
                    pageSize : 12, //当前页展示多少
                    total : 0, // 数据总量
                },
                loading : true,
            }
        },
        created() {
            this.queryParams = this.$route.query;
            this.getList();
        },
        methods : {
            getList(){
                let { name , id , period_id } = this.queryParams;
                let { page, pageSize } = this.paginationParams;
                let data = {
                    committee_id : id,
                    period_id,
                    limit : pageSize,
                    page
                }
                this.loading = true;
                this.request.post('PeriodMember',data).then(res => {
                    let formatTemplate = {
                        name : 'real_name',
                        subject : 'department',
                        company : 'org_cnname',
                        avatar : 'avatar',
                        introduce : 'resume',
                        user_id : 'UserID',
                        subject:'position',
                        introduce:'resume'
                    }
                    let data = res.data.list;
                    res.data.list = jsonFormat(data,formatTemplate);
                    res.data.count = parseInt(res.data.count)
                    this.pageInfo = res.data;
                    this.loading = false;
                })
            },
            handleChangeSize(current,size){
                console.log(current,size);
                this.paginationParams.pageSize = size;
                this.getList();
            },
        }
    }
</script>

<style scoped lang="less">
    .content{
        h1{
            font-weight: bold;
            margin-bottom: 0;
        }
        .count{
            font-weight: bold;
            font-size: 26px;
        }

        .block{
            .item-box{
                min-height: 400px;
            }
            .title{
                text-align: left;
                font-size: 20px;
            }
            
        }
    }
    @media screen and (max-width:768px){
        .container{
            padding: 10px;
            .content{
                margin-top: 0;
            }
        }
    }
</style>